import gql from "graphql-tag";

/*
  Plans
*/

export const PlanFragment = gql`
  fragment plan on PaymentPlan {
    id
    name
    package {
      id
      name
    }
    currency {
      id
      name
    }
    period
    duration
    type
    pricing
    configuration
    status
    activeSubscribers
    companyIds
  }
`;

/*
  Subscriptions
*/

export const SubscriptionFragment = gql`
  fragment subscription on PaymentSubscription {
    id
    to
    from
    status
    company {
      id
      name
      credit
    }
    prorate
    duration
    taxation
    currentTier
    planName
    paymentType: type
    ratePerPeriod: rpp
    billingPeriod: period
  }
`;

/*
  Payments
*/

export const PaymentFragment = gql`
  fragment payment on Payment {
    id
    company
    details
    amount
    receivedAmount
    method
    due_date
    invoice
    status
  }
`;
